import * as React from "react"
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import LayoutComponent from "../components/global/layout"
import SEOComponent from "../components/global/seo";

const NotFoundPage = ({ data }) => {
  const image = getImage(data.imageSharp)

  return (
    <LayoutComponent>
      <SEOComponent title="4-Oh!-4" 
           metaTitle="4-Oh!-4 | Surinder Bhomra"
           metaDescription="Well isn't this awkward?"
           shareImageIsLarge={true}
      />    
      <div className="divide-y">
        <header className="pt-6 pb-8 space-y-2 md:space-y-5">
          <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-6xl md:leading-14"> 
            4-Oh!-4
          </h1>
          <p>Well isn't this awkward?</p>
        </header>
        <div className="space-y-2 xl:space-y-0">
          <div className="sm:pt-6 flex flex-row items-center">
              <GatsbyImage image={image} className="mx-auto" alt="Calvin and Hobbs Searching" />
          </div>
        </div>
      </div>
    </LayoutComponent>
  )
}

export const query = graphql`
  query {
    imageSharp(fluid: {originalName: {eq: "CalvinAndHobbsSearching.jpeg"}}) {
      fluid {
        src
        originalName
      }
      gatsbyImageData(width: 900, placeholder: BLURRED)
    }
  }
`

export default NotFoundPage
